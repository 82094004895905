<template>
	<a id="to-top" @click="scrollToTop()">
		<font-awesome-icon icon="fa-light fa-arrow-up" size="1x" />
	</a>
</template>

<script setup>
const handleScroll = () => {
	const fixedElements = document.querySelectorAll('.to-top');
	if (window.pageYOffset >= 350) {
		fixedElements.forEach((el) => el.classList.add('show'));
	} else {
		fixedElements.forEach((el) => el.classList.remove('show'));
	}
};

const scrollToTop = () => {
	window.scroll({
		top: 0,
		left: 0,
		behavior: 'smooth',
	});
};

onBeforeMount(() => {
	window.addEventListener('scroll', handleScroll);
});

onBeforeUnmount(() => {
	window.removeEventListener('scroll', handleScroll);
});
</script>

<style lang="scss" scoped>
#to-top {
	opacity: 0;
	position: fixed;
	top: auto;
	right: 25px;
	bottom: 15px;
	z-index: 10;
	width: 48px;
	height: 48px;
	line-height: 48px;
	text-align: center;
	cursor: pointer;
	box-shadow: 0 0 5px rgb(0 0 0 / 15%);
	color: var(--primary-text-color);
	font-size: 18px;
	transition: all 0.4s ease-out;
	background-color: var(--primary-background-color);
}

#to-top.show {
	opacity: 1;
}
</style>

<template>
	<div>
		<footer>
			<div class="row">
				<div class="columns column3">
					<h3>Budapest</h3>
					<span>Hotel template</span>
				</div>
				<div class="columns column3">
					<h4>{{ defaults[locale].website.hotelName }}</h4>
					<p>
						<span>{{ defaults[locale].website.street }} {{ defaults[locale].website.number }}</span
						><br />
						<span>{{ defaults[locale].website.postalCode }} {{ defaults[locale].website.city }}</span
						><br />
						<span>{{ defaults[locale].website.country }}</span
						><br />
					</p>
				</div>
				<div class="columns column3">
					<h4>{{ $t('footerContact') }}</h4>
					<p>
						<a :href="`tel:${$phoneRaw(defaults[locale].website.phone)}`">
							<span>{{ defaults[locale].website.phone }}</span> </a
						><br />
						<a :href="`mailto:${defaults[locale].website.email}`"
							><span>{{ defaults[locale].website.email }}</span></a
						>
					</p>
					<social-media :socials="socials" />
				</div>
				<div class="columns column3">
					<h4>{{ $t('footerNavigate') }}</h4>
					<p>
						<nuxt-link v-for="item in footermenu[locale]" :key="item.filename" :to="item.filename">
							{{ item.header }} <br />
						</nuxt-link>
					</p>
				</div>
			</div>
			<div class="row author">
				<div class="columns column12 align-center">
					<div class="footer-border" />
					<p>
						<a
							href="https://www.porterforhotels.com/hotel-websites"
							target="_blank"
							rel="noopener noreferrer"
							title="Hotel website design by Porter"
						>
							Hotel Website Design
						</a>
						by:
						<a href="https://porterforhotels.com" target="_blank">
							<img class="siteByLogo" :src="$t('siteByLogo')" loading="lazy" alt="" />
							Porter</a
						>
					</p>
				</div>
			</div>
		</footer>
	</div>
</template>

<script setup>
const { data: footermenu } = await useWebsiteFetch('footermenu');
const { locale } = useI18n();

const { defaults, socials, fetchDefaults } = useDefaults();

await fetchDefaults();
</script>

<style lang="scss" scoped>
footer {
	background: var(--footer-background-color);
	color: var(--footer-link-color);
	padding: 100px 0 10px;
	font-size: 0.85rem;

	h3,
	h4 {
		color: var(--footer-link-color);
	}
}

.footer-border {
	background: #154263;
	width: 100%;
	height: 1px;
	margin: 0 0 20px;
}

a {
	color: var(--footer-link-color);
	text-decoration: none;

	&:hover {
		color: var(--primary-color);
	}
}

.author {
	margin: 50px auto 0;
	font-size: 12px;
	padding: 20px 0 10px;

	p {
		margin: 0;
		font-size: 12px;
	}

	img {
		max-width: 20px;
		margin: 0 1px 0 2px;
	}
}

@media (max-width: 900px) {
	footer .columns {
		margin: 20px auto;
		text-align: left;
	}

	footer.author .columns {
		text-align: center;
	}
}

@media (max-width: 600px) {
	footer {
		.columns {
			text-align: center;
		}

		.socials {
			justify-content: center;
		}
	}
}
</style>
